<template>
  <div class="fragment">
    <FedexUkraineReportTableAdmin
        v-if="isAdmin"
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @resetFilter="resetFilter"
        @showMore="showMore"
        @changeFilter="changeFilter"
        @reload="reload"
    />


  </div>

</template>

<script>
  import FedexUkraineReportTableAdmin from "./FedexUkraineReportAdmin/FedexUkraineReportAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "FedexUkraineReportTable",
    components: {
      FedexUkraineReportTableAdmin,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getFedexUkraineReportForPage,
        page: 1,
        onePage: 1,

        filterIdExpress: '',
        filterExpressTrack: '',

        filterDate: [],

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          idExpress: this.$route.query.idExpress,
          expressTrack: this.$route.query.expressTrack,
          date: this.$route.query.date,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextFedexUkraineReportPage', true)
        this.$store.dispatch('fetchFedexUkraineReport', url).then(() => {
          this.$store.commit('setNextFedexUkraineReportPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter()
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        this.generateFilterQueryParams(
          myQuery,
          {
            filterIdExpress: 'express_id',
            filterExpressTrack: 'express_tracking_number',
          },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
              'between_created_at',
              date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }

  }
</script>

<style scoped>

</style>
