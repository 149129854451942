<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">
<!--        <div class="content-tabs__item " v-bind:class="{active: tab === 1}" @click="changeTab(1)">-->
<!--          <div class="content-tabs__ico">-->
<!--           -->
<!--          </div>-->
<!--        </div>-->
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-right-block">
          <MainButton class="btn-fit-content ml-3"
                    :value="'Create'"
                    :ico="'ico'"
                    @click.native="changeFedexUkraineReportPopup(true)"
        >
          <template slot="ico">
            <PlusIconSVG class="btn-icon-plus"/>
          </template>
        </MainButton>
      </div>
    </div>

    <FedexUkraineReportPopup
            v-if="isModalFedexUkraineReportPopup"
            :type="'create'"
            @reload="$emit('reload')"
            @close="changeFedexUkraineReportPopup(false)"
    />


  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  import FedexUkraineReportPopup from "../../../../popups/FedexUkraineReportPopup/FedexUkraineReportPopup";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "FedexUkraineReportHead",
    components: {
      FedexUkraineReportPopup,
      ToggleFilterButton,
      MainButton,
      PlusIconSVG,
      // DefaultCheckbox,
      // SearchEngine,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    data(){
      return{

        isModalFedexUkraineReportPopup: false,
      }
    },

    methods: {

      changeFedexUkraineReportPopup(val, id = false) {
        if(id){
          this.orderId = id
        }
        this.isModalFedexUkraineReportPopup = val

        if(!val){
          this.orderId = -1
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";


</style>

