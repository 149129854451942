<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['forbiddenKeywords_Id'])"></div>
        <DefaultInput
            :label="'Express' + $t('forbiddenKeywords_Id.localization_value.value')"
            :type="'text'"
            v-model="idExpress"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_trackingNumber'])"></div>
        <DefaultInput
            :label="$t('returnGoods_trackingNumber.localization_value.value')"
            :type="'text'"
            v-model="expressTrack"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_Created',])"></div>
        <DatePickerDefault
            :label="$t('poshta_Created.localization_value.value')"
            v-model="date"
        >
          <template slot="body">
            <date-picker
                v-model="date"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import DatePickerDefault from "@/components/UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'

  export default {
    name: "FedexUkraineReportFilter",
    components: {
      DatePickerDefault,
      FilterBlock,
      MainButton,
      DefaultInput,
      DatePicker,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc],

    data(){
      return {

        idExpress: this.filterGetParams.idExpress ? this.filterGetParams.idExpress : '',
        expressTrack: this.filterGetParams.expressTrack ? this.filterGetParams.expressTrack : '',
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',

        filterCounts: [
          'idExpress',
          'expressTrack',
          'date',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.idExpress = newVal.idExpress ? newVal.idExpress : ''
        this.expressTrack = newVal.expressTrack ? newVal.expressTrack : ''
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)

        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },
    },
  }
</script>

<style scoped>

</style>
