<template>
  <div>
    <FedexUkraineReportHead
            :countFilterParams="countFilterParams"
            :showFilter="showFilter"
            @toggleFilter="toggleFilter"
            @filter="$emit('filter')"
            @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">
      <FedexUkraineReportFilter
              :showFilter="showFilter"
              :filterGetParams="filterGetParams"
              @close="closeFilter"
              @changeFilter="data => $emit('changeFilter', data)"
              @resetFilter="$emit('resetFilter')"
      />

      <FedexUkraineReportTable
          :countFilterParams="countFilterParams"
              @showMore="$emit('showMore')"
              @resetFilter="$emit('resetFilter')"
              @updateTable="$emit('updateTable')"
              @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import FedexUkraineReportHead from "./FedexUkraineReportHead/FedexUkraineReportHead";
  import FedexUkraineReportFilter from "./FedexUkraineReportFilter/FedexUkraineReportFilter";
  import FedexUkraineReportTable from "./FedexUkraineReportTable/FedexUkraineReportTable";

  export default {
    name: "FedexUkraineReportTableAdmin",

    components: {
      FedexUkraineReportHead,
      FedexUkraineReportFilter,
      FedexUkraineReportTable,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },
    }
  }
</script>

<style scoped>

</style>
