<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'reports_CreateReport',
          ])"></div>
        {{$t('reports_CreateReport.localization_value.value')}}
      </div>

    </template>
    <template slot="body">
      <div class="profiles-expenses__content">

        <div class="profiles-expenses__row custom-row">
          <div class="profiles-expenses__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_date'])"></div>

            <DatePickerDefault
                :label="$t('common_date.localization_value.value')"
                v-model="FUR.data.date"
            >
              <template slot="body">
                <date-picker
                    v-model="FUR.data.date"
                    ref="datePicker"
                    valueType="format"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
                ></date-picker>
              </template>
            </DatePickerDefault>
          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-between align-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>

        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                v-if="type === 'create'"
                :value="$t('common_create.localization_value.value')"
                class="profiles-expenses__btn-i"
                @click.native="save"
        />
<!--        <MainButton-->
<!--                v-else-->
<!--                :value="$t('common_update.localization_value.value')"-->
<!--                class="profiles-expenses__btn-i"-->
<!--                @click.native="save"-->
<!--        />-->
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import {FedexUkraineReport} from "../../models/FedexUkraineReport";
  import DatePickerDefault from "@/components/UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'


  export default {
    name: "FedexUkraineReportPopup",
    components: {
      DatePickerDefault,
      DatePicker,
      Modal,
      MainButton,
    },


    props: {
      itemId: Number,
      type: String,
    },

    data() {
      return {
        FUR: new FedexUkraineReport()
      }
    },

    mounted() {
      this.FUR.data.date = this.$moment(new Date()).format('MM/DD/YY')
      // if(this.itemId){
      //   this.$store.dispatch('getFedexUkraineReport', this.itemId).then(() => {
      //     this.FK.setItem(this.$store.getters.getFedexUkraineReportItem)
      //   }).catch(error => this.createErrorLog(error))
      // }
    },

    methods: {
      save() {
        if(!this.FUR.firstValidation()) return

        let saveType = 'createFedexUkraineReport'

        let date = this.FUR.prepareSave(this)

        // if (this.type === 'update') {
        //   saveType = 'updateFedexUkraineReport'
        //   data = {
        //     data: data,
        //     id: this.itemId
        //   }
        // }

        this.$store.dispatch(saveType, date).then((response) => {

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              if (this.type === 'update') {
                this.openNotify('success', 'common_notificationRecordChanged')
              } else {
                this.openNotify('success', 'common_notificationRecordCreated')
              }
              this.$emit('close')
              this.$emit('reload')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      }
    }

  }

</script>

<style lang="scss" scoped>
  @media(max-width: 550px){
    .transaction-info__col{
      width: 100%;
    }

    .scoped-btns > button{
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      width: fit-content;
      margin-bottom: 10px;
    }
  }
</style>
